<template lang="pug">
.stats.fixed
  span.fps(ref='stats') 0
canvas.canvas(ref='canvas')
</template>

<script>
window.__DEBUG__ = false;

import createREGL from 'regl';
import createCamera from 'regl-camera';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { createSkyCommand } from '@/commands/particle-sky/index.js';
import { createSkyCommand1 } from '@/commands/particle-sky/sky.js';

export default {
  name: 'ParticleSky',
  setup() {
    const canvas = ref(null);
    const stats = ref(null);
    let regl;

    onMounted(() => {
      const onResize = () => {
        canvas.value.width = window.innerWidth * 2;
        canvas.value.height = window.innerHeight * 2;
      };
      window.addEventListener('resize', onResize);
      onResize();

      regl = createREGL({ canvas: canvas.value });
      const camera = createCamera(regl, {
        center: [0, 0, 0],
        distance: 1.8,
        damping: 0,
        theta: 0,
        phi: 0,
        fovy: 100,
        near: 0.1,
        far: 50000,
      });

      let time = 0;
      // const draw = createSkyCommand(regl);
      const draw = createSkyCommand1(regl);
      let t;
      let c = 0;
      const update = ({ time: reglTime }) => {
        time = reglTime;
        if (!t) {
          t = time;
        }
        c += 1;
        if (c >= 60) {
          const fps = c / (time - t);
          c = 0;
          t = time;
          stats.value.textContent = fps;
        }
        draw({ time, p: 1 });
      };

      regl.frame(({ time }) => {
        camera(() => {
          update({ time });
        });
      });
      // regl.frame(update);
    });

    onBeforeUnmount(() => regl?.destroy());

    return { canvas, stats };
  },
};
</script>

<style lang="stylus" scoped>
*
  box-sizing border-box

.fixed
  position fixed
  left 0
  top 0

.absolute
  position absolute
  left 0
  top 0

.stats
  z-index 2
  color #fff
  width 100%
  padding 0 10px
  display flex
  justify-content space-between
  color cyan

.p-controller
  width 100%
  height 500vh

.canvas
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  background-color black
  cursor pointer

  img
    display none

.tips
  width 100%
  display flex
  align-items flex-end
  justify-content center
  pointer-events none
  top auto
  bottom 0
  color yellow
</style>
