import frag from './particle-sky.frag';
import vert from './particle-sky.vert';
import mat4 from 'gl-mat4';

const PI_2 = 2 * Math.PI;

const getRandom = (n = 0, m = 1) => Math.random() * (m - n) + n;
const getEmptyArr = (count) => Array(count).fill(0);
const getRandomArray = (count, n = 0, m = 1) => getEmptyArr(count).map(() => getRandom(n, m));

// const setAroundParticles = (pathPos, count) => {
//   const finalPos = [...pathPos];
//   pathPos.forEach((pos) => {
//     const x = pos[0];
//     const y = pos[1];
//     const z = pos[2];
//     for (let i = 0; i < count; i++) {
//       const angle = getRandom() * PI_2;
//       const radius = getRandom();
//       const x1 = x + Math.cos(angle) * radius;
//       const z1 = z + Math.sin(angle) * radius;
//       const y1 = getRandom(y - 0.1, y + 0.1);
//       finalPos.push([x1, y1, z1, getRandom(-1, 1) / 5]);
//     }
//   });
//   return finalPos;
// };

export const createSkyCommand = (regl) => {
  const position = getEmptyArr(2000).map(() => [0, 1, 0, getRandom(0, 1.5)]);
  // const position = setAroundParticles(initialPos, 15);
  const count = position.length;

  return regl({
    vert,
    frag,
    attributes: {
      a_position: position,
      a_xzSpread: getRandomArray(count, 1, 1.05),
      a_random1: getRandomArray(count),
      a_random2: getRandomArray(count),
      a_random3: getRandomArray(count),
      a_speed: getRandomArray(count, 0.01, 0.02),
    },
    uniforms: {
      u_t: ({ time }) => time,
      view: () => mat4.lookAt([], [0, 0, 2.4], [0, 0, 0], [0, 1, 0]),
      projection: ({ viewportWidth, viewportHeight }) =>
        mat4.perspective([], Math.PI / 4, viewportWidth / viewportHeight, 0.01, 10),
    },
    count: position.length,
    primitive: 'points',
    depth: { enable: true },
    blend: {
      enable: true,
      func: {
        srcRGB: 'src alpha',
        srcAlpha: 'src alpha',
        dstRGB: 'dst alpha',
        dstAlpha: 'dst alpha',
      },
    },
  });
};
