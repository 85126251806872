<template lang="pug">
.stats.fixed
  span.fps(ref='stats') 0
canvas.canvas(ref='canvas')
</template>

<script>
window.__DEBUG__ = false;

import createREGL from 'regl';
import createCamera from 'regl-camera';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { createBgCommand } from '@/commands/particle-bg/index.js';

export default {
  name: 'ParticleBg',
  setup() {
    const canvas = ref(null);
    const stats = ref(null);
    let regl;

    onMounted(() => {
      const onResize = () => {
        canvas.value.width = window.innerWidth * 2;
        canvas.value.height = window.innerHeight * 2;
      };
      window.addEventListener('resize', onResize);
      onResize();

      regl = createREGL({ canvas: canvas.value });

      let time = 0;
      const draw = createBgCommand(regl);
      let t;
      let c = 0;
      const update = ({ time: reglTime }) => {
        time = reglTime;
        if (!t) {
          t = time;
        }
        c += 1;
        if (c >= 60) {
          const fps = c / (time - t);
          c = 0;
          t = time;
          stats.value.textContent = fps;
        }
        draw({ time });
      };

      regl.frame(update);
    });

    onBeforeUnmount(() => regl?.destroy());

    return { canvas, stats };
  },
};
</script>

<style lang="stylus" scoped>
*
  box-sizing border-box

.fixed
  position fixed
  left 0
  top 0

.absolute
  position absolute
  left 0
  top 0

.stats
  z-index 2
  color #fff
  width 100%
  padding 0 10px
  display flex
  justify-content space-between
  color cyan

.p-controller
  width 100%
  height 500vh

.canvas
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  background-color black
  cursor pointer

  img
    display none

.tips
  width 100%
  display flex
  align-items flex-end
  justify-content center
  pointer-events none
  top auto
  bottom 0
  color yellow
</style>
