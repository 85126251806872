import vert from './wave-text.vert';
import frag from './wave-text.frag';
import mat4 from 'gl-mat4';

import { getTextPixels } from '../star-text/getTextInfo';

export async function createWaveTextCommand(regl) {
  const {
    pixelsData,
    size: { width, height },
  } = await getTextPixels('Wave Text', {
    fontSize: '200px',
    fontFamily: 'OPPOSans-Medium',
  });

  const viewportWidth = window.innerWidth * window.devicePixelRatio;
  const viewportHeight = window.innerHeight * window.devicePixelRatio;

  const finalPosition = pixelsData.map(({ pos }) => {
    const x = ((pos[0] * 2) / width - 1) * (width / viewportWidth);
    const y = ((-pos[1] * 2) / height + 1) * (height / viewportHeight);
    return [x, y, Math.random()];
  });

  return regl({
    vert,
    frag,
    attributes: {
      a_finalPosition: finalPosition,
    },
    depth: { enable: false },
    blend: {
      enable: false,
      func: {
        srcRGB: 'src alpha',
        srcAlpha: 'src alpha',
        dstRGB: 'dst alpha',
        dstAlpha: 'dst alpha',
      },
    },
    uniforms: {
      u_t: ({ time }) => time,
      u_texture: (_, { texture }) => texture,
    },
    count: pixelsData.length,
    primitive: 'points',
  });
}
