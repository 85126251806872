<template lang="pug">
canvas.canvas(ref='canvas')
  img(ref='img', src='~@/assets/star.png')
</template>

<script>
window.__DEBUG__ = false;

import createREGL from 'regl';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { createWaveTextCommand } from '@/commands/wave-text/index.js';

export default {
  name: 'ParticleBg',
  setup() {
    const canvas = ref(null);
    const img = ref(null);

    /** @type {ReturnType<typeof createREGL>} */
    let regl;

    onMounted(async () => {
      const onResize = () => {
        canvas.value.width = window.innerWidth * window.devicePixelRatio;
        canvas.value.height = window.innerHeight * window.devicePixelRatio;
      };
      window.addEventListener('resize', onResize);
      onResize();

      let texture;
      img.value.addEventListener('load', () => {
        texture = regl.texture(img.value);
      });

      regl = createREGL({ canvas: canvas.value });
      const draw = await createWaveTextCommand(regl);

      const update = () => {
        draw({ texture });
      };

      regl.frame(update);
    });

    onBeforeUnmount(() => regl?.destroy());
    return { canvas, img };
  },
};
</script>

<style lang="stylus" scoped>
*
  box-sizing border-box

.fixed
  position fixed
  left 0
  top 0

.absolute
  position absolute
  left 0
  top 0

.stats
  z-index 2
  color #fff
  width 100%
  padding 0 10px
  display flex
  justify-content space-between
  color cyan

.p-controller
  width 100%
  height 500vh

.canvas
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  background-color black
  cursor pointer

  img
    display none

.tips
  width 100%
  display flex
  align-items flex-end
  justify-content center
  pointer-events none
  top auto
  bottom 0
  color yellow
</style>
