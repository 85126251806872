// import { dots } from './dots';
import mat4 from 'gl-mat4';
import vert from './particle-bg.vert';
import frag from './particle-bg.frag';

const random = (min = 0, max = 1) => Math.random() * (max - min) + min;

const getEmptyArr = (count) => Array(count).fill(0);

const getRandomArray = (count, n = 0, m = 1) => getEmptyArr(count).map(() => random(n, m));

export const createBgCommand = (regl) => {
  const count = 1000;
  const position = [];

  for (let i = 0; i < count; i += 1) {
    const angle = random() * 2 * Math.PI;
    const amplitude = random() * 0.85 + 0.15;
    position.push([amplitude * Math.sin(angle), amplitude * Math.cos(angle), random(), random()]);
    // position.push([random() * 2 - 1, random() * 2 - 1, random(), random()]);
  }

  return regl({
    vert,
    frag,
    attributes: {
      position,
      a_random0: getRandomArray(count),
      a_random1: getRandomArray(count),
      size: getRandomArray(count, 1, 2),
      speed: getRandomArray(count, 1, 4),
    },
    uniforms: {
      time: ({ time }) => time,
      view: () => mat4.lookAt([], [0, 0, 2], [0, 0, 0], [0, 1, 0]),
      projection: ({ viewportWidth, viewportHeight }) =>
        mat4.perspective([], Math.PI / 4, viewportWidth / viewportHeight, 0.1, 100),
    },
    count: position.length,
    primitive: 'points',
    depth: { enable: true },
    blend: {
      enable: true,
      func: {
        srcRGB: 'src alpha',
        srcAlpha: 'src alpha',
        dstRGB: 'dst alpha',
        dstAlpha: 'dst alpha',
      },
    },
  });
};
